import React from 'react'
import logo from './good.png'
import './App.css'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeRoute />}></Route>
        <Route path="/shakedown" element={<ShakedownRoute />}></Route>
      </Routes>
    </div>
  )
}

function HomeRoute() {
  return (
    <>
      <h2>This CDN hosting project has loaded successfully.</h2>
      <img src={logo} alt="img" height="350" />
    </>
  )
}

function ShakedownRoute() {
  return (
    <>
      <h2>This CDN hosting project route has loaded successfully.</h2>
      <img src={logo} alt="img" height="350" />"
    </>
  )
}

export default App
